import { flatMap, set } from "lodash";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { IGame } from "../../shared/interfaces/game.interface";
import { ILevel } from "../../shared/interfaces/level.interface";
import {
    ISound,
    ISoundResponse,
} from "../../shared/interfaces/sound.interface";
import {
    IPlayAGamePayload,
    getGameOptionsRequest,
    playAGameRequest,
} from "../../shared/services/game/game.service";
import backButtonImage from "./../../../assets/images/back-button.png";
import lockIcon from "./../../../assets/images/lock.png";
import LevelSelectorModal from "./LevelSelectorModal";
import PositionAndLevelInfoModal from "./PositionAndLevelInfoModal";
import SoundSelectorModal from "./SoundSelectorModal";
import SubscriptionModal from "./SubscriptionModal";
import ModalAfterLogin from "../../shared/components/OneTimeModal";
import { updateUser } from "../../shared/services/user/user.service";
import VideoModal from "./VideoModal";
import TutorialButton from "./components/TutorialButton";
import { wordPositionEnum } from "../../shared/enums/word-position.enum";
import { LevelEnum } from "../../shared/enums/level.enum";
import ReactTooltip from "react-tooltip";
import { InformationCircleIcon } from "@heroicons/react/solid";
import WhosPlayingComponent from "./components/whosPlayingComponent";
import { ACTIONS } from "../../shared/context/authContext/Constants";
import { IUser } from "../../shared/interfaces/user.interface";
import { getHubspotToken, loggedUserWithStudent, setParentProfile } from "../../shared/services/auth/auth.service";
import { getFlashcardsRequest, IGetFlashcardsPayload } from "../../shared/services/flashcards/flashcard.service";
import { getReadingContentsRequest, IGetReadingContentsPayload } from "../../shared/services/reading/reading.service";

type WordPositionType = {
    title: string;
    subTitle: string;
}

const LetsPlay = () => {
    const history = useHistory();
    const location = useLocation();
    const params = {
        sound: new URLSearchParams(location.search).get("sound"),
        level: new URLSearchParams(location.search).get("level"),
        wordPosition: new URLSearchParams(location.search).get("wordPosition"),
    }

    const { state, dispatch } = useContext(AuthContext);

    const [formData, setFormData] = useState<any>({
        game: null,
        sound: null,
        wordPositions: [],
        level: null,
        setMode: 0,
    });

    const [sounds, setSounds] = useState<ISound[]>([]);

    const [showSoundsModal, setShowSoundsModal] = useState(false);

    const [showOneTimeModal, setShowOneTimeModal] = useState(false);

    const [selectedSound, setSelectedSound] = useState<ISound>();

    const [childSelectedSound, setchildSelectedSound] = useState<ISound>();

    const [wordPositions, setWordPositions] = useState<WordPositionType[]>([]);

    const [wordPositionsAllowedBySound, setWordPositionsAllowedBySound] = useState<wordPositionEnum[]>([]);

    const [levelAllowedBySound, setLevelsAllowedBySound] = useState<LevelEnum[]>([]);

    const [wordPositionsSelected, setWordPositionsSelected] = useState<WordPositionType[]>([]);

    const [levels, setLevels] = useState<ILevel[]>([]);

    const [showLevelsModal, setShowLevelsModal] = useState(false);

    const [readingSelectedLevel, setReadingLevelSelected] = useState<any>();

    const [levelSelected, setLevelSelected] = useState<ILevel>();

    const [games, setGames] = useState<IGame[]>([]);

    const [inputLevel, setInputLevel] = useState(0);

    const [allSelected, setAllSelected] = useState(false);

    const [lockSubscription, setLockSubscription] = useState(false);

    const [activeInfoModal, setActiveInfoModal] = useState<
        "WORDPOSITION" | "LEVEL" | "SOUND" | "NONE"
    >("NONE");

    const [isVideoModalOpen, setIsVideoModalOpen] = useState(
        localStorage.getItem("preview-video") === "1"
    );

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const [showWhosPlaying, setWhosPlaying] = useState(false);

    const [hsToken, setHsToken] = useState(null);

    const [availableStep2, setAvailableStep2] = useState(false);

    const [availableStep3, setAvailableStep3] = useState(false);
    
    const [availableStep4, setAvailableStep4] = useState(false);

    const [flashcardsAvailable, setFlashcardsAvailable] = useState(false);

    const [readingContentAvailable, setReadingContentAvailable] = useState(false);

    const [readingContent, setReadingContent] = useState<any>([]);

    const getHsToken = async (user: IUser) => {
        const { response } = await getHubspotToken({
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
        });

        setHsToken(response?.token);
        (window as any).hsConversationsSettings = {
            loadImmediately: false,
            identificationEmail: user.email,
            identificationToken: response?.token || '',
        };
        setTimeout(() => {
            (window as any)?.HubSpotConversations?.widget?.refresh();
            (window as any)?.HubSpotConversations?.widget?.load();
        }, 1000);
    }

    const fetchListGames = async () => {
        const { response } = await getGameOptionsRequest();

        const childrenSounds: ISound[] = flatMap(
            response.sounds.map((sound: ISoundResponse) => sound.children)
        );

        const sounds = response.sounds
            .filter((sound: ISoundResponse) => {
                return (
                    childrenSounds.findIndex(
                        (child: ISound) => child.id === sound.parent.id
                    ) === -1
                );
            })
            .map((sound: ISoundResponse) => {
                return {
                    ...sound.parent,
                    children: sound.children,
                };
            });

        setSounds(sounds);

        const wordPositionsWithAll = [ ...response.wordPositions.map((x: string) => {
            return {
                title: x,
                subTitle: "",
            };
        }), {title: "ALL", subTitle: ""}];

        setWordPositions(
            wordPositionsWithAll
        );

        const levels = response.levels.map((level: ILevel) => {
            if (level.title === "READING") {
                return {
                    ...level,
                    children: response.readingLevels,
                };
            }

            return level;
        })

        setLevels(levels);

        if(params?.sound){          
            let wordPositionSelectedByParams: WordPositionType[] = [];  
            sounds.forEach((x: ISound) => {
                let sound: ISound | undefined;

                if(x.children.length){
                    sound = x.children.find((y: ISound) => y.title === params.sound);
                    if(sound) {
                        setchildSelectedSound(sound);
                    }
                }
                
                if(x.title === params.sound){
                    sound = x;
                }

                if(sound){
                    setSelectedSound(sound);
                    setWordPositionsAllowedBySound(sound.wordPositionsAllowed || []);
                    setLevelsAllowedBySound(sound.levelsAllowed || []);

                    const level = levels.find((x: ILevel) => x.title === params.level);
            
                    if(level && sound.levelsAllowed?.includes(level.title)){
                        setLevelSelected(level);              
                        setReadingLevelSelected(level);
                        if(level.title === 'READING'){
                            setShowLevelsModal(true);                        
                        }
                    }

                    if(params.wordPosition && sound.wordPositionsAllowed?.includes(params.wordPosition as wordPositionEnum)){
                        wordPositionSelectedByParams = wordPositionsWithAll.filter((x: WordPositionType) => x.title === params.wordPosition)
                        setWordPositionsSelected(wordPositionSelectedByParams);
                    }

                    setFormData((currentState: any) => {
                        return {
                            ...currentState,
                            sound: sound?.id,
                            level: level?.id,
                            wordPositions: wordPositionSelectedByParams
                        };
                    });
                }
            });        
        }

        setGames(response.games.filter((x: any) => !x.reinforcement));
    };

    const getUserFromApi = async () => {
        const { response, errors } = await loggedUserWithStudent();
        if (!errors) {
            const students = response?.parentInfo?.students?.length
                ? response.parentInfo.students.map(
                      (student: Partial<IUser>) => {
                          return {
                              id: student.id,
                              firstname: student.firstname,
                              lastname: student.lastname,
                              fullname: `${student.firstname} ${student.lastname}`,
                          };
                      }
                  )
                : [];

            dispatch({
                type: ACTIONS.SET_STUDENTS_UNDER_USER,
                students,
            });

            if (!response.profileStudentUsed) {
                if(students?.length === 1) {
                    await setParentProfile(students[0].id);
                }
            }
        }
    };

    const validateFlashCards = async () => {
        if (wordPositionsSelected.length === 0) return

        const args: any = {
            sound: formData.sound,
            wordPosition: wordPositionsSelected[wordPositionsSelected.length - 1].title
        }

        if(wordPositionsSelected.find(w => w.title === 'ALL')){
            delete args.wordPosition;
        }
        
        const payload: IGetFlashcardsPayload = {
            args
        };

        try {
            const { response, errors } = await getFlashcardsRequest(payload);
            
            if (response?.pagingMeta?.count >= 4) {
                setFlashcardsAvailable(true)
                return 
            }
            
            if (errors?.length) {
                setFlashcardsAvailable(false)
                return
            }


            setFlashcardsAvailable(false)
        } catch (error: any) {
            setFlashcardsAvailable(false)
            console.error('ERROR:', error);
        }
    };

    const validateReadingContent = async () => {
        
        if (
            wordPositionsSelected.length > 1 &&
            !wordPositionsSelected.some((w) => w.title === "ALL")
        ) {
            setReadingContentAvailable(false);
            return;
        }

        let wordPosition = wordPositionsSelected.some((w) => w.title === "ALL") ? 'ALL' : wordPositionsSelected[0]?.title;

        const payload: IGetReadingContentsPayload = {
            args: {
                sound: formData.sound,
                wordPosition
            },
        };

        try {
            const { response, errors } = await getReadingContentsRequest(payload);
            const readingLevels = levels.find((l: any) => l.title === "READING")?.children;

            if (errors?.length) {
                setReadingContentAvailable(false);
                return;
            }

            const hasSufficientContent =
                response?.pagingMeta?.count >= 1 &&
                readingLevels.length > 0 &&
                readingLevels.some((level: string) => response.data.filter((x: any) => x.readingLevel === level).length >= 1);

            setReadingContentAvailable(true);
            if (hasSufficientContent) {
                setReadingContent(response.data)
            } else {
                setReadingContent([])
            };
        } catch (error: any) {
            console.error("ERROR:", error);
            setReadingContentAvailable(false);
        }
    };
    

    useEffect(() => {
        if (state) {
            if(state && state.user && !state.user?.hideLoginPopUpWeb){
                setShowOneTimeModal(true);
                updateUser({ hideLoginPopUpWeb: true });
            }
            if(state.user && !hsToken) {
                getHsToken(state.user);
            }
            setLockSubscription(state?.user?.subscriptionTier === "FREE");
        }
    }, [state]);

    useEffect(() => {
        fetchListGames();
        getUserFromApi();
    }, []);

    useEffect(() => {
        setFormData((currentState: any) => {
            return {
                ...currentState,
                wordPositions: !!params.sound ? currentState.wordPositions : wordPositionsSelected.filter(word => word.title !== "ALL"),
            };
        });
        setAvailableStep3(wordPositionsSelected.length > 0);
        setAvailableStep4(!!(wordPositionsSelected.length > 0 && levelSelected && levelSelected?.title !== 'READING'));
        validateFlashCards()
        validateReadingContent()
    }, [wordPositionsSelected]);

    useEffect(() => {
        const { sound, wordPositions, level, game } = formData || {};

        let step = 0;

        if (!sound) {
            setAvailableStep2(false);
            setAvailableStep3(false);
            setAvailableStep4(false);
        }

        if (sound != null) {
            step = 1;
            setAvailableStep2(true);
        }

        if (wordPositions.length && level == null) {
            step = 2;
        }

        if (level != null && game == null) {
            step = 3;
        }

        if (game != null) {
            step = 4;
        }

        if (sound && wordPositions?.length && level && game && levelSelected?.title !== 'READING') {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }

        setInputLevel(step);
    }, [formData]);

    const onSubmit = async (readingLevel = undefined) => {
        let amount = 10;
        let gameTitle: string = formData.game?.title?.toLocaleLowerCase();
        if(gameTitle?.includes('puzzle')){
            amount = 25;
        }

        if (readingLevel && !gameTitle && formData.wordPositions.length === 1) { 
            amount = 1;
        }
            
        const payload: IPlayAGamePayload = {
            game: formData.game?.id,
            level: formData.level,
            sound: formData.sound,
            wordPositions: formData.wordPositions.map(
                (position: any) => position.title
            ),
            amount,
            readingLevel: readingLevel,
            deviceType: 'WEB'
        };
        
        try {
            const { response, errors } = await playAGameRequest(payload);

            if (response) {
                localStorage.setItem("gamePayload", JSON.stringify(formData));
                localStorage.setItem("gameAssets", JSON.stringify(response));

                //Set the selected level
                localStorage.setItem(
                    "levelSelected",
                    JSON.stringify(levelSelected)
                );

                history.push("/app/game");
            }
            if(errors?.length){
                toast.error(errors[0]?.message || 'Something went wrong');
            }
        } catch (error: any) {
            toast.error(error?.message);
        }
    };

    const renderStep1 = () => {
        const renderSoundItem = (sound: ISound, index: number) => (
            <div
                key={sound.id}
                className={`justify-self-center  rounded-full h-14 w-14 text-white flex flex-col  justify-center hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200 relative
                                        ${
                                            formData.sound === sound.id ||
                                            sound.children.find(
                                                (childrenSound) =>
                                                    childrenSound.id ===
                                                    formData?.sound
                                            )
                                                ? "bg-aha-orange"
                                                : "bg-aha-black"
                                        }

                                        ${
                                            lockSubscription &&
                                            sound.subscription
                                                ? "cursor-not-allowed"
                                                : null
                                        }
                                    `}
                onClick={() => {
                    setWordPositionsAllowedBySound(sound.wordPositionsAllowed || []);
                    setLevelsAllowedBySound(sound.levelsAllowed || []);
                    setWordPositionsSelected([]);
                    setLevelSelected(undefined);
                    if (!(lockSubscription && sound.subscription)) {
                        if (sound.children.length) {
                            setSelectedSound(sound);          
                            setWordPositionsSelected([]);
                            setShowSoundsModal(true);
                        }

                        setFormData((currentState: any) => {
                            return {
                                ...currentState,
                                level: undefined,
                                sound:
                                    currentState.sound === sound.id
                                        ? null
                                        : sound.id,
                            };
                        });
                    } else {                        
                        setShowSubscriptionModal(true);
                    }
                }}
            >
                {lockSubscription && sound.subscription ? (
                    <div className="absolute -top-1 -right-1 h-5 w-5 pointer-events-none z-50">
                        <img src={lockIcon} alt="Lock" />
                    </div>
                ) : null}
                <div className={"tracking-widest font-century-gothic-bold"}>
                    {sound.title}
                </div>
                {sound.subTitle && (
                    <div className={"text-xs -mt-2 tracking-widest"}>
                        {sound.subTitle}
                    </div>
                )}
            </div>
        );

        return (
            <div
                className={`flex-1 border-4 border-gray text-center pb-4 relative  ${
                    inputLevel === 0 && "bg-gray-100"
                }`}
            >
                <div className="mb-3 mt-6">
                    <div className="mx-auto border w-max border-black rounded-full px-3">
                        Step 1
                    </div>

                    <div className="my-5 text-aha-black">
                        <p className="text-2xl mb-2 font-century-gothic-bold">
                            Sounds
                        </p>
                        <p className="">Choose the sound your</p>
                        <p className="">child is working on</p>
                    </div>

                    <TutorialButton
                        type="sound"
                        onClick={() => setActiveInfoModal("SOUND")}
                    />
                </div>

                <div
                    className={`text-2xl font-blanch-regular items-center py-6 ${
                        sounds.length < 10 ? "mx-12" : "mx-2"
                    } grid grid-cols-3 sm:grid-cols-4 md:grid-cols-3  gap-3`}
                >
                    {sounds.map((sound: ISound, index: number) =>
                        renderSoundItem(sound, index)
                    )}
                </div>
            </div>
        );
    };

    const renderStep2 = () => {

        const highLightItem = (item: WordPositionType) => {
            if( wordPositionsSelected.some( word => word.title === item.title) && item.title === 'ALL' ){
                return true;
            }

            if( !wordPositionsSelected.some( word => word.title === 'ALL') ){
                return wordPositionsSelected.some(word => word.title === item.title);
            }

            return false

        } 
        
        const isAllowedToSelect = (item: WordPositionType) => availableStep2 && wordPositionsAllowedBySound.includes(item.title as wordPositionEnum);
        const hasAllowedValues = (item: WordPositionType) => availableStep2 && wordPositions.some( w => wordPositionsAllowedBySound.includes(w.title as wordPositionEnum)) && item.title ===  'ALL';

        const renderWordItem = (position: any, index: number) => (            
            <div
                key={position.title + index}
                className={`py-3 ${isAllowedToSelect(position) || hasAllowedValues(position) ? 'hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200' : '' }
                        ${
                            highLightItem(position)
                                ? "bg-aha-orange"
                                : isAllowedToSelect(position) || hasAllowedValues(position) ? "bg-aha-black" : "bg-gray-300"
                        }
                        `}
                onClick={() => {
                    if(isAllowedToSelect(position) || position.title === 'ALL'){
                        setWordPositionsSelected(currentState  => {
                            const response = [ ...wordPositionsAllowedBySound.map( value => { return {
                                title: value,
                                subTitle: ''
                            }}), { title: 'ALL', subTitle: '' }]
                            if( !currentState.some(word => word.title === position.title) && position.title === 'ALL' ){
                                return response;
                            }
    
                            if( currentState.some(word => word.title === position.title) && position.title === 'ALL' ){
                                return [];
                            }
    
                            if( currentState.some(word => word.title === position.title) ){
                                if(currentState.some(word => word.title === 'ALL') ){
                                    return currentState.filter( (x: any) => x.title === position.title ).filter( (x: any) => x.title !== 'ALL' );
                                }else{
                                    return currentState.filter( (x: any) => x !== position );
                                }
                            }
                            
                            if( !currentState.some(word => word.title === position.title) ){
                                const itemsSelected = [...currentState, position];
    
                                return itemsSelected.length === response.length -1 ? response : itemsSelected;
                            }
                        
                            return currentState;
                        });
                    }                        
                }}
            >
                <div
                    className={
                        "text-xl tracking-widest font-century-gothic-bold"
                    }
                >
                    {position.title}
                </div>
                <div className={"font-blanch-light tracking-wide text-3xl"}>
                    {position.subTitle}
                </div>
            </div>
        );

        return (
            <div
                className={`flex-1 border-4 border-gray text-center pb-4 relative ${
                    inputLevel === 1 && "bg-gray-100"
                }`}
            >
                <div className="mb-3 mt-6">
                    <div className="mx-auto border w-max border-black rounded-full px-3">
                        Step 2
                    </div>

                    <div className="my-5 text-aha-black">
                        <p className="text-2xl mb-2 font-century-gothic-bold">
                            Word Position
                        </p>
                        <p className="">Choose the position of</p>
                        <p className="">the sound in words</p>
                    </div>

                    <TutorialButton
                        type="word"
                        onClick={() => setActiveInfoModal("WORDPOSITION")}
                    />
                </div>

                <div
                    className={
                        "space-y-8 uppercase pt-8 px-2 md:px-8 text-white"
                    }
                >
                    {wordPositions.map((position: any, index: number) =>
                        renderWordItem(position, index)
                    )}
                </div>
            </div>
        );
    };

    const renderStep3 = () => {
        const isAllowedToSelect = (level: ILevel) => {
            if(!availableStep3) return false
            if (level.title === 'READING' && !readingContentAvailable) return false
            
            const requiresFlashcards = ['WORD', 'PHRASE', 'SENTENCE'].includes(level.title);
            if (requiresFlashcards) {
                return flashcardsAvailable && levelAllowedBySound.includes(level.title as LevelEnum);
            }
            
            return level.title === 'READING'
                ? (wordPositionsSelected.length < 2 || wordPositionsSelected?.some(w => w.title === 'ALL')) && levelAllowedBySound.includes(level.title as LevelEnum)
                : levelAllowedBySound.includes(level.title as LevelEnum);
        };


        const renderLevelItem = (level: ILevel | any, index: number) => (
            <div
                key={level.id}
                className={` py-3 relative ${isAllowedToSelect(level) ? 'hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200' : '' }
                                    ${
                                        formData.level === level.id && availableStep3
                                            ? "bg-aha-orange"
                                            : isAllowedToSelect(level) ? "bg-aha-black" : "bg-gray-300"
                                    }
                                    ${
                                        lockSubscription && level.subscription && !availableStep3
                                            ? "cursor-not-allowed"
                                            : null
                                    }
                                    `}
                onClick={() => {
                    if(isAllowedToSelect(level)){
                        if (!(lockSubscription && level.subscription)) {
                            setLevelSelected(level);
                            if (level?.children?.length) {
                                setShowLevelsModal(true);
                                setReadingLevelSelected({
                                    ...level,
                                    wordPositionsSelected
                                });
                            }
                            setFormData((currentState: any) => {
                                return {
                                    ...currentState,
                                    level:
                                        currentState.level === level.id
                                            ? null
                                            : level.id,
                                    setMode: index,
                                };
                            });

                            setAvailableStep4(level.title !== 'READING')
                        } else {
                            setShowSubscriptionModal(true);
                        }
                    }
                }}
            >
                {
                    level.title === 'READING' && !isAllowedToSelect(level) && wordPositionsSelected.length > 1 && !wordPositionsSelected?.some(w => w.title === 'ALL') ? (
                        <>
                            <InformationCircleIcon data-tip data-for="readingLevelTip" className={"hover:cursor-pointer w-4 text-white absolute top-0 right-0"} />
                            <ReactTooltip id="readingLevelTip" type="info" place="top" effect="solid">
                                <span>
                                    Note: Reading level prompts require one word position only
                                </span>
                            </ReactTooltip>
                        </>
                    ) : null
                }
                {lockSubscription && level.subscription ? (
                    <div className="absolute w-6 h-6 -top-3 -right-2  pointer-events-none z-50">
                        <img src={lockIcon} alt="Lock" />
                    </div>
                ) : null}
                <div
                    className={
                        "text-2xl tracking-wider font-century-gothic-bold relative"
                    }
                >
                    {level.title}
                </div>
            </div>
        );

        return (
            <div
                className={`flex-1 border-4 border-gray text-center pb-4 relative ${
                    inputLevel === 2 && "bg-gray-100"
                }`}
                style={{ minHeight: 600 }}
            >
                <div className="mb-3 mt-6">
                    <div className="mx-auto border w-max border-black rounded-full px-3">
                        Step 3
                    </div>

                    <div className="my-5 text-aha-black">
                        <p className="text-2xl mb-2 font-century-gothic-bold">
                            Level
                        </p>
                        <p className="">
                            Select the level of speech complexity
                        </p>
                        <p className="">your child is working on</p>
                    </div>

                    <TutorialButton
                        type="level"
                        onClick={() => setActiveInfoModal("LEVEL")}
                    />
                </div>

                <div
                    className={
                        "space-y-8 uppercase pt-8 px-2 md:px-8 text-white"
                    }
                >
                    {levels.map((level: ILevel | any, index: number) =>
                        renderLevelItem(level, index)
                    )}
                </div>
            </div>
        );
    };

    const renderStep4 = () => {
        const renderGameItem = (game: IGame, index: number) => (
            <div
                key={game.id}
                className={`${
                    availableStep4 ? "hover:bg-aha-orange" : ""
                } cursor-pointer transition ease-in-out duration-200 py-3 relative ${
                    !availableStep4 ? 'bg-gray-300': formData.game === game
                        ? "bg-aha-orange"
                        : "bg-aha-black"
                } ${
                    !availableStep4 || (lockSubscription && game.subscription)
                        ? "cursor-not-allowed"
                        : null
                }`}
                onClick={() => {
                    if (availableStep4) {
                        if (!(lockSubscription && game.subscription)) {
                            setFormData((currentState: any) => {
                                return {
                                    ...currentState,
                                    game:
                                        currentState.game === game
                                            ? null
                                            : game,
                                };
                            });
                        } else {
                            setShowSubscriptionModal(true);
                        }
                    }
                }}
            >
                {lockSubscription && game.subscription ? (
                    <div className="absolute w-6 h-6 -top-3 -right-2  pointer-events-none z-50">
                        <img src={lockIcon} alt="Lock" />
                    </div>
                ) : null}
                <div className="text-2xl tracking-wider font-century-gothic-bold">
                    {game.title}
                </div>
            </div>
        );

        return (
            <div className={"flex-1 text-center"}>
                <div className={"flex flex-col gap-6 h-full"}>
                    <div
                        className={`border-4 border-gray pb-4 flex-grow ${
                            inputLevel === 3 && "bg-gray-100"
                        }`}
                    >
                        <div className="mb-3 mt-6">
                            <div className="mx-auto border w-max border-black rounded-full px-3">
                                Step 4
                            </div>

                            <div className="my-5 text-aha-black">
                                <p className="text-2xl mb-2 font-century-gothic-bold">
                                    Pick Your Game
                                </p>
                            </div>
                        </div>

                        <div
                            className={
                                "space-y-8 uppercase pt-8 px-2 md:px-8 text-white"
                            }
                        >
                            {games.map((game: IGame, index: number) =>
                                renderGameItem(game, index)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const onPlayPressed = () => {
        if(state?.user?.students?.length && state.user.students.length > 1) {
            setWhosPlaying(true)
        } else {
            onSubmit();
        }
    }

    return (
        <div className="container">
            {
                !showWhosPlaying ? (
                    <div className={"pt-4 pb-16"}>
                        <Link to={"/"}>
                            <img
                                src={backButtonImage}
                                className="h-14 mb-4 cursor-pointer"
                                alt="Back button"
                            />
                        </Link>
                        <div className="mb-4 flex flex-col lg:items-center lg:grid lg:grid-cols-4 gap-4">
                            <div className="col-span-2">
                                <p className="text-xl md:text-2xl font-century-gothic-bold md:mb-0">
                                    Build your game in 4 easy steps
                                </p>
                            </div>
                            <div></div>
                            <button
                                className={`text-white text-lg tracking-widest py-2 cursor-pointer ${
                                    allSelected
                                        ? "bg-aha-orange"
                                        : "bg-gray-200 pointer-events-none"
                                }`}
                                onClick={() => onPlayPressed()}
                            >
                                Let's Play!
                            </button>
                        </div>

                        <div
                            className={
                                "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
                            }
                        >
                            {renderStep1()}

                            {renderStep2()}

                            {renderStep3()}

                            {renderStep4()}
                        </div>

                        
                        <ModalAfterLogin 
                            showModal={showOneTimeModal} 
                            onClose={(ev: boolean) => {
                                setShowOneTimeModal(ev);
                            }}
                        />

                        <PositionAndLevelInfoModal
                            showModal={isVideoModalOpen}
                            onClose={(ev: any) => setActiveInfoModal(ev)}
                            activeInfoModal={activeInfoModal}
                        />

                        <VideoModal
                            showModal={isVideoModalOpen}
                            onClose={(ev: any) => setIsVideoModalOpen(!isVideoModalOpen)}
                        />

                        <SoundSelectorModal
                            selectedSound={selectedSound}
                            childSelectedSound={childSelectedSound}
                            showModal={showSoundsModal}
                            lockSubscription={lockSubscription}
                            onClose={(sound: ISound | undefined) => {
                                setShowSoundsModal(false);
                                setWordPositionsSelected([]);
                                setLevelSelected(undefined);
                                setWordPositionsAllowedBySound(sound?.wordPositionsAllowed || []);
                                setLevelsAllowedBySound(sound?.levelsAllowed || []);
                                setchildSelectedSound(sound);
                                setFormData((currentState: any) => {
                                    return {
                                        ...currentState,
                                        sound: sound ? sound.id : null,
                                    };
                                });
                            }}
                            onLockClick={() => setShowSubscriptionModal(true)}
                        />

                        <LevelSelectorModal
                            showModal={showLevelsModal}
                            onClose={(level: any) => {
                                setShowLevelsModal(false);
                                if(level){
                                    onSubmit(level);
                                } else {
                                    setFormData((currentState: any) => {
                                        return {
                                            ...currentState,
                                            level: null
                                        };
                                    });
                                }
                            }}
                            selectedLevel={readingSelectedLevel}
                            readingContent={readingContent}
                            sound={formData.sound}
                        />

                        <SubscriptionModal
                            showModal={showSubscriptionModal}
                            onClose={() => setShowSubscriptionModal(false)}
                        />
                    </div>
                ) : (
                    <WhosPlayingComponent setWhosPlaying={setWhosPlaying} onSubmit={onSubmit} />
                )
            }
        </div>

    );
};

export default LetsPlay;
