import React, { useEffect, useState } from "react";
import closeImage from "./../../../assets/images/bake-a-cake/close.png";
import Modal from "react-modal";
import ReactModal from "react-modal";
import { ILevelSelectorModalOption } from "../../shared/interfaces/modal-option.interface";
import letsPlayArrowImage from "./../../../assets/images/lets-play-arrow.png";
import { toast } from "react-toastify";
import { ReadingLevelEnum } from "../../shared/enums/reading-level.enum";

ReactModal.defaultStyles.overlay.backgroundColor = "#00000060";

interface IReadingContent {
    id: string;
    readingLevel: string;
    wordPosition: string;
    __typename: string;
}

const customStyles = {
    content: {
        top: "30%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -30%)",
        width: 500,
        minHeight: 500,
        padding: "1rem",
        border: "15px solid #262262",
    },
};

const LevelSelectorModal = ({
    showModal,
    onClose,
    selectedLevel,
    readingContent,
    sound,
}: ILevelSelectorModalOption) => {
    const [userSelectedLevel, setUserSelectedLevel] =
        useState<ReadingLevelEnum>();
    const readingLevelsAvailable =
        readingContent.length > 0 && selectedLevel?.children
            ? Object.fromEntries(
                  selectedLevel?.children.map((readingLevel: string) => [
                      readingLevel,
                      readingContent.filter(
                          (c: IReadingContent) =>
                              c.readingLevel === readingLevel
                      ).length >= 1,
                  ])
              )
            : {};

    useEffect(() => {
        setUserSelectedLevel(undefined);
    }, [sound])

    return (
        <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
            <div className="flex justify-end">
                <img
                    src={closeImage}
                    alt="Close Modal"
                    className="w-10 cursor-pointer"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>

            <div className="text-center">
                Select the reading level
                <br />
                you would like to practice:
            </div>

            <div className="space-y-4 font-blanch-regular text-3xl gap-6 my-4">
                {selectedLevel?.children?.map((level: any) => {
                    const isAvailable = readingLevelsAvailable[level];
                    return (
                        <div
                            key={level}
                            className={` justify-self-center py-3 text-white flex flex-col items-center justify-center ${isAvailable ? 'hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200 relative' : 'cursor-not-allowed'} ${
                                userSelectedLevel === level
                                    ? "bg-aha-orange"
                                    : isAvailable ? "bg-aha-black" : "bg-gray-300"
                            }`}
                            onClick={() => {
                                if (isAvailable) {
                                    setUserSelectedLevel(level);
                                }
                            }}
                        >
                            <div
                                className={
                                    "tracking-widest font-century-gothic-bold"
                                }
                            >
                                {level.replace(/_/g, " ")}
                            </div>
                        </div>
                    );
                })}
                {selectedLevel?.title === "READING" ? (
                    readingContent.length > 0 ? 
                        <div className="space-y-2">
                            <div
                                className={
                                    "text-white uppercase text-6xl tracking-widest py-6 flex-none cursor-pointer bg-cover bg-center bg-no-repeat font-blanch-regular text-center w-3/4 mx-auto"
                                }
                                style={{
                                    backgroundImage: `url(${letsPlayArrowImage})`,
                                }}
                                onClick={() => {
                                    if (!userSelectedLevel) {                          
                                        toast.error(
                                            "Please select the reading level."
                                        );
                                    }

                                    onClose(userSelectedLevel);
                                }}
                            >
                                Let's go
                            </div>

                            <div className="text-center text-sm font-serif">
                                *You will not select a game for the reading level.
                            </div>
                        </div>
                    : <div
                            className={
                                "bg-gray-200 text-black uppercase text-6xl tracking-widest py-6 flex-none cursor-pointer font-blanch-regular text-center w-3/4 mx-auto"
                            }
                            onClick={() => {
                                if (!userSelectedLevel) {                          
                                    toast.error(
                                        "Please select the reading level."
                                    );
                                }

                                onClose(userSelectedLevel);
                            }}
                        >
                            Let's go
                        </div>
                ) : null}
            </div>
        </Modal>
    );
};

export default LevelSelectorModal;
